@import "style-functions";
@import "style-config";

.splash-screen {
  width: 100%;
  height: 100vh;
  padding-top: 10vh;
  background-color: #101823;
  top: 0;
  position: absolute;
  z-index: 90;
  opacity: 1;
  transition: opacity 0.5s;
}

.hide {
  opacity: 0;
}

.legal-notice,
.terms-of-sales {
  //text-align: center;

  @include md {
    padding: 0 20px;
  }

  h1 {
    color: $color-highlight;
    font-style: italic;
    @include font-size(35px);
    max-width: 650px;
    margin: 0 auto 25px;
  }
  h2 {
    color: $color-highlight;
    font-style: italic;
    @include font-size(24px);
    max-width: 650px;
    margin: 0 auto 25px;
  }
  .subtitle {
    color: $color-highlight;
    font-style: italic;
    font-weight: bold;
    @include font-size(20px);
    max-width: 650px;
    margin: 30px auto 15px;
    text-align: left;
  }
  .undertitle {
    color: $color-highlight;
    font-weight: bold;
    @include font-size(16px);
    max-width: 650px;
    margin: 30px auto 15px;
    text-align: left;
  }

  p, ul {
    max-width: 650px;
    margin: 0 auto 25px;
    @include font-size(16px);
    line-height: 28px;
    letter-spacing: 1px;
    text-align: left;
  }
  ul {
    padding-left: 25px;
  }
}

.button {
  padding: 15px 25px;
  background-color: $color-highlight-light;
  border-radius: 20px;
  color: $color-highlight;
  font-weight: bold;
  text-transform: uppercase;
  cursor: pointer;
  margin-left: auto;
  margin-right: auto;
  width: fit-content;
  @include transition(all ease-in-out 0.2s);

  &:hover {
    background-color: $color-highlight;
    color: white;
  }
}
